import { useIntl } from 'react-intl'

import { convertUTCtoMSK, formatSum } from '../../../../utils'
import { IsGranted } from '../../auth/PrivateRoute'
import { ROLE, useAuth } from '../../auth'
import { IPayoutTicket } from '../payoutTickets.types'

const TicketItem = ({ ticket, typePage }: { ticket: IPayoutTicket; typePage?: string }) => {
  const intl = useIntl()
  const { currentUser } = useAuth()

  return (
    <tr>
      <td>
        <a href={'/payout-tickets/ticket-info/' + ticket.token}>{ticket.token}</a>
      </td>
      <td>{convertUTCtoMSK(ticket.createdAt)}</td>
      {typePage !== 'Active' && ticket.completionDate && (
        <td>{convertUTCtoMSK(ticket.completionDate)}</td>
      )}
      <td>{ticket.amount ? formatSum(ticket.amount) : '-'}</td>
      {IsGranted([ROLE.ROLE_ADMIN, ROLE.ROLE_MANAGER], currentUser) && (
        <td>{ticket.externalUser?.id}</td>
      )}
      <td>{ticket.externalUser?.externalId}</td>
      <td>{ticket.merchant.title}</td>
      {typePage === 'Active' ? (
        <td>
          {IsGranted([ROLE.ROLE_ADMIN, ROLE.ROLE_MANAGER], currentUser) && ticket.cashBox
            ? ticket.cashBox.title
            : ''}
        </td>
      ) : (
        <td>{ticket.executor ? ticket.executor.uuid : ''}</td>
      )}
      <td>{intl.formatMessage({ id: 'ticket.status.' + ticket.status })}</td>
    </tr>
  )
}

export default TicketItem
