export enum ETicketStatus {
  ACTIVE = 'ACTIVE',
  IN_PROGRESS = 'IN_PROGRESS',
  DECLINED = 'DECLINED',
  COMPLETED = 'COMPLETED',
  CREATED = 'CREATED',
  WAITING_FOR_REQUISITE = 'WAITING_FOR_REQUISITE',
  WAITING_FOR_SMS = 'WAITING_FOR_SMS',
  WAITING_FOR_MERCHANT = 'WAITING_FOR_MERCHANT',
  WAITING_FOR_ACTION = 'WAITING_FOR_ACTION',
  NOT_CONFIRMED = 'NOT_CONFIRMED',
  CLIENT_PENDING = 'CLIENT_PENDING',
  PAYMENT_PENDING = 'PAYMENT_PENDING',
}

export enum ETransactionStatus {
  ACTIVE = 'ACTIVE',
  IN_PROGRESS = 'IN_PROGRESS',
  DECLINED = 'DECLINED',
  COMPLETED = 'COMPLETED',
}

export enum ERequisiteEvent {
  CANCEL_RESERVATION = 'CANCEL_RESERVATION',
  CREATE = 'CREATE',
  CREATE_RESERVATION = 'CREATE_RESERVATION',
  ENABLE_RESERVATION = 'ENABLE_RESERVATION',
  DISABLE_RESERVATION = 'DISABLE_RESERVATION',
  COMPLETE_RESERVATION = 'COMPLETE_RESERVATION',
  AUTO_CANCEL_RESERVATION = 'AUTO_CANCEL_RESERVATION',
  AUTO_COMPLETE_RESERVATION = 'AUTO_COMPLETE_RESERVATION',
  HIDDEN = 'HIDDEN',
}

export enum EPaymentMethods {
  MC_VISA_UAH = 'MC/VISA UAH',
  PRIVAT24_P2P_UAH = 'PRIVAT24 P2P UAH',
  MC_VISA_MIR_P2P_RUB = 'MC/VISA/MIR P2P RUB',
  SBP_P2P_RUB = 'SBP P2P RUB',
}
