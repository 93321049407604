import { ChangeEvent, useEffect, useMemo, useState } from 'react'
import ReactPaginate from 'react-paginate'

import { PageLink, PageTitle } from '../../../../_metronic/layout/core'
import { Table } from '../../../../components/Table'
import { TableHead } from '../../../../components/Table/TableHead'
import { TableBody } from '../../../../components/Table/TableBody'
import { ETicketStatus } from '../../../../types/enums'
import { ROLE, useAuth } from '../../auth'
import { IsGranted } from '../../auth/PrivateRoute'
import { getTicketsList } from '../api/getTicketsList'
import { IParamsTicketsListRequest } from '../api/ticketsList.types'
import { PayoutTicketListObject, PreparePayoutTicketsToObject } from '../core/_models'

import TicketItem from './TicketItem'

import '../../../assets/css/pagination.css'

const accountBreadCrumbs: Array<PageLink> = [
  {
    title: 'Активные',
    path: '/payout-tickets/active',
    isSeparator: false,
    isActive: false,
    roles: [
      ROLE.ROLE_ADMIN,
      ROLE.ROLE_MANAGER,
      ROLE.ROLE_SENIOR_CASHIER,
      ROLE.ROLE_CASHIER,
      ROLE.ROLE_SENIOR_OPERATOR,
      ROLE.ROLE_OPERATOR,
    ],
  },
  {
    title: 'Завершённые',
    path: '/payout-tickets/finished',
    isSeparator: false,
    isActive: true,
    roles: [
      ROLE.ROLE_ADMIN,
      ROLE.ROLE_MANAGER,
      ROLE.ROLE_SENIOR_CASHIER,
      ROLE.ROLE_CASHIER,
      ROLE.ROLE_SENIOR_OPERATOR,
      ROLE.ROLE_OPERATOR,
    ],
  },
  {
    title: 'Отчёт',
    path: '/payout-tickets/report',
    isSeparator: false,
    isActive: false,
    roles: [
      ROLE.ROLE_ADMIN,
      ROLE.ROLE_MANAGER,
      ROLE.ROLE_SENIOR_CASHIER,
      ROLE.ROLE_SENIOR_OPERATOR,
    ],
  },
]

const perPage = 20
const defaultTicketList: PayoutTicketListObject = {}

const FinishedTickets = () => {
  const { currentUser } = useAuth()
  const [tickets, setTickets] = useState(defaultTicketList)
  const [countPages, setCountPages] = useState(0)
  const [fullCount, setFullCount] = useState(0)
  const [searchDebounce, setSearchDebounce] = useState<any>(null)
  const [loading, setLoading] = useState(false)

  const getTicketList = async (page: number, ticketToken?: string) => {
    const query: IParamsTicketsListRequest = {
      perPage: perPage,
      status: [ETicketStatus.COMPLETED, ETicketStatus.DECLINED],
      page: page,
    }

    if (ticketToken) {
      query.ticketToken = ticketToken
    }

    setLoading(true)

    try {
      const response = await getTicketsList(query, currentUser)

      if (!response.data) {
        return false
      }

      if (response.data.success && response.data.tickets) {
        setTickets(PreparePayoutTicketsToObject(response.data.tickets))
        setCountPages(Math.ceil(response.data.totalRows / perPage))
        setFullCount(response.data.totalRows)
      }

      setLoading(false)
    } catch (err) {
      console.log('error catched: ', err)
    }
  }

  useEffect(() => {
    getTicketList(1)
  }, [])

  const onSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    clearTimeout(searchDebounce)
    const newTimer = setTimeout(() => getTicketList(1, e.target.value), 500)
    setSearchDebounce(newTimer)
  }

  const ticketsRender = useMemo(
    () => Object.keys(tickets).map((key) => <TicketItem ticket={tickets[key]} key={key} />),
    [tickets]
  )

  return (
    <div>
      <div className='card sticky-top-tickets mb-7'>
        <PageTitle breadcrumbs={accountBreadCrumbs}>Завершённые</PageTitle>
        <div className='card-header border-0 pt-5 pb-2'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>Завершённые тикеты</span>
            <span className='text-muted mt-1 fw-semibold fs-7'>Количество: {fullCount}</span>
          </h3>
        </div>
      </div>

      <div className='card mb-7'>
        <div className='card-body border-0 pt-5'>
          <div className='col-lg-5'>
            <label className='fs-6 form-label fw-bolder text-dark'>Поиск</label>
            <div className='position-relative me-md-2'>
              <span className='svg-icon svg-icon-3 svg-icon-gray-500 position-absolute top-50 start-0 translate-middle ms-5'>
                <i className='fa fa-search'></i>
              </span>
              <input
                type='text'
                className='form-control form-control-solid ps-10'
                onChange={onSearchChange}
                placeholder='Поиск по "ticketToken": xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx'
              />
            </div>
          </div>
        </div>
      </div>

      <div className='card'>
        <div className='card-body py-3'>
          <Table>
            <TableHead>
              <tr>
                <th className='min-w-120px'>Id</th>
                <th className='min-w-150px'>Дата создания</th>
                <th className='min-w-150px'>Дата закрытия</th>
                <th className='min-w-120px'>Сумма</th>
                {IsGranted([ROLE.ROLE_ADMIN, ROLE.ROLE_MANAGER], currentUser) && (
                  <th className='min-w-50px'>User id</th>
                )}
                <th className='min-w-120px'>External user id</th>
                <th className='min-w-100px'>Merchant id</th>
                <th className='min-w-120px'>Сотрудник</th>
                <th className='min-w-100px'>Статус</th>
              </tr>
            </TableHead>
            <TableBody>
              {loading ? (
                <tr>
                  <td colSpan={8}>Загрузка ...</td>
                </tr>
              ) : (
                ticketsRender
              )}
            </TableBody>
          </Table>
        </div>
      </div>

      <ReactPaginate
        activeClassName={'active-pagination '}
        breakClassName={'item-pagination break-me-pagination '}
        containerClassName={'pagination-pagination'}
        disabledClassName={'disabled-page-pagination'}
        nextClassName={'item-pagination ki-duotone ki-right fs-2x'}
        pageClassName={'item-pagination pagination-page-pagination '}
        previousClassName={'item-pagination ki-duotone ki-left fs-2x'}
        breakLabel='...'
        nextLabel=''
        onPageChange={(pageNumber) => getTicketList(pageNumber.selected + 1)}
        pageRangeDisplayed={5}
        pageCount={countPages}
        previousLabel=''
        renderOnZeroPageCount={null}
      />
    </div>
  )
}

export default FinishedTickets
